<template>
  <div>
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <div class="sec-title">老师详情</div>

      <el-row>
        <el-col :xs="24" :sm="3">
          <el-image
            style="width: auto; height: 10rem"
            :src="teaInfo.photo"
          ></el-image>
        </el-col>
        <el-col :xs="24" :sm="5" class="details">
          <div>姓名: {{ teaInfo.full_name }}</div>
          <div>在职时间: {{ teaInfo.master_age }}</div>
          <div>性别: {{ teaInfo.sex == 1 ? "男" : "女" }}</div>
        </el-col>
        <el-col :xs="24" :sm="5" class="details">
          <div>联系电话: {{ teaInfo.mobile }}</div>
          <div>科目: {{ teaInfo.categories_child }}</div>
          <div>
            所属{{
              this.$route.query.type == "mec"
                ? "机构"
                : this.$route.query.type == "sch"
                ? "学校"
                : "教育局"
            }}:{{ $route.query.couchMec }}
          </div>
          <div>
            状态:
            <span v-if="teaInfo.status == 1" style="color: yellow"
              >正在审核</span
            >
            <span v-if="teaInfo.status == 2" style="color: green"
              >审核通过</span
            >
            <span v-if="teaInfo.status == 3" style="color: red">审核拒绝</span>
            <span v-if="teaInfo.status == 4" style="color: grey">注销</span>
            <span v-if="teaInfo.status == 5" style="color: red">冻结</span>
            <span v-if="teaInfo.status == 6">未启用</span>
            <el-button
              v-if="teaInfo.status == 1 "
              type="success"
              size="small"
              @click="update()"
              >通过审核</el-button
            >
            <!-- <el-button
              type="success"
              size="small"
              @click="update()"
              >通过审核</el-button
            > -->
            <el-button
              v-if="teaInfo.status == 1"
              type="success"
              size="small"
              @click="refuse()"
              >审核拒绝</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teaInfo: {},
      mechanism_name_list: [],
      if_clear: true,
      categories_list: [],
      loading: false,
    };
  },
  computed: {
    nav() {
      // console.log(this.$route.query.type);
      if (this.$route.query.type == "sch") {
        return {
          firstNav: "智慧校园中心",
          secondNav: "老师详情",
        };
      } else if (this.$route.query.type == "mec") {
        return {
          firstNav: "机构管理中心",
          secondNav: "教练详情",
        };
      } else if (this.$route.query.type == "edu") {
        return {
          firstNav: "教育局管理中心",
          secondNav: "老师详情",
        };
      }
    },
  },
  methods: {
    getTeaDetails() {
      let url = "/user/masterInfo/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.$route.query.couchID,
          },
        })
        .then((res) => {
          this.teaInfo = res.data.data;
          // console.log(this.teaInfo);
        });
    },
    update() {
      let url = "/user/masterInfo/updateMechanismMasterInfo";
      this.$axios
        .post(url, {
          id: this.teaInfo.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getTeaDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    refuse() {
      let url = "/user/masterInfo/updateMechanismMasterInfo";
      this.$axios
        .post(url, {
          id: this.teaInfo.id,
          status: 3,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getTeaDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
  },
  created() {
    this.getTeaDetails();
  },
};
</script>

<style>
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
  font-size: 1.5rem;
}
.alter .el-row > span {
  width: 7rem;
  display: inline-block;
  margin-right: 2rem;
  text-align: right;
  font-size: 1.5rem;
  color: #636567;
}
.alter .el-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
/* .alter .el-row .el-input {
  width: 60% !important;
} */
/* .avatar-uploader {
  display: inline-block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
} */
</style>
